import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query-devtools@5.17.9_@tanstack+react-query@4.32.6_react@18.2.0/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@uploadthing+react@6.4.0_next@14.1.0_react@18.2.0_uploadthing@6.6.0/node_modules/@uploadthing/react/next-ssr-plugin/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.1.1/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.2/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-circular\",\"display\":\"swap\",\"src\":[{\"path\":\"../../styles/fonts/CircularStd-Book.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../styles/fonts/CircularStd-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../styles/fonts/CircularStd-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"circular\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/nextjs-toploader@1.6.4_next@14.1.0_react-dom@18.2.0_react@18.2.0/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/PostHogPageView.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/trpc/react.tsx")