'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

export default function PostHogPageView(props: {
  userId: string | undefined;
  email: string | null | undefined;
  name: string | null | undefined;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture('$pageview', {
        $current_url: url
      });
    }
  }, [pathname, searchParams, posthog]);

  useEffect(() => {
    if (!!props?.userId) {
      posthog.identify(props.userId, {
        ...(!!props?.email && { email: props.email }),
        ...(!!props?.name && { name: props.name })
      });
    }
  }, []);

  return null;
}
